<template>
  <ion-page>
    <HeaderPage title="Giveaway" />
    <ion-content :fullscreen="true">
      <div class="container">
        <ul class="list">
          <li class="item flex align-center" v-for="e in events" :key="e.id" :class="{'active': (e.active == 1)}" @click="$router.push({name: 'EventDetail', params: {id: e.id} })">
            <div class="media">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 12V22H4V12" :stroke="e.active  == 1? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M22 7H2V12H22V7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 22V7" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" :stroke="e.active  == 1 ? '#F2C94C': '#C4C4C4'" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="content flex-half">
              <div class="a text-bold">{{ e.name }}</div>
              <div class="b" v-if="e.active == 1">Sedang berlangsung</div>
              <div class="b" v-if="e.active == 2 || e.active == 0">{{ e.winners_count }} pemenang | {{ e.testimoni_count }} testimoni</div>
            </div>
            <div class="end" style="padding: 13px;">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12L10 8L6 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </li>
        </ul>
      </div>
      <ion-infinite-scroll
        @ionInfinite="loadEvents"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInfiniteScroll, IonInfiniteScrollContent  } from '@ionic/vue';
import HeaderPage from '@/components/headerPage'
export default {
  components: {
    IonContent,
    IonPage,
    HeaderPage,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  data () {
    return {
      page: 1,
      events: [],
      isDisabled: false
    }
  },
  mounted () {
    this.loadEvents()
  },
  methods: {
    async loadEvents (e) {
      let data = await this.$api.get('/api/event', {params: {page: this.page}}).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.events.push(c)
      })

      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },
    openDetail(e) {
      this.$router.push({name: 'EventDetail', params: {id: e.id} })
    }
  }
}
</script>

<style lang="scss">
.left {
    width: 25%;
}
</style>
